import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FinanceCategory } from 'src/app/model/finance-category.model';
import { FinanceRecurringType } from 'src/app/model/finance-recurring-type.model';
import { FinanceTransactionPrototype } from 'src/app/model/finance-transaction-prototype.model';
import { FinanceTransaction } from 'src/app/model/finance-transaction.model';
import { TimeFrameFilter } from 'src/app/model/timeframe-filter.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceCategoryService } from 'src/app/services/finance-category.service';
import { FinanceTransactionService } from 'src/app/services/finance-transaction.service';
import { FinanceTransactionPrototypeService } from 'src/app/services/finance-transactionPrototype.service';
import { FinanceRecurringTypeService } from 'src/app/services/finance-transactionRecurringType.service';
import { TimeframeAdjustEvent } from 'src/app/shared/timeframe-filter/timeframe-adjusted-event';
import { FinanceMenuItems } from '../FinanceMenuItems';

@Component({
  selector: 'app-finance-transaction',
  templateUrl: './finance-transaction.component.html',
  styleUrls: ['./finance-transaction.component.scss'],
})
export class FinanceTransactionComponent {
  constructor(
    private topNavService: TopNavService,
    private financeTransactionProtoypeService: FinanceTransactionPrototypeService,
    private financeCategoryService: FinanceCategoryService,
    private financeRecurringTypeService: FinanceRecurringTypeService,
    private financeTransactionService: FinanceTransactionService,
    private router: Router) {
      const state = this.router.getCurrentNavigation()?.extras.state;
      if (!state) {
        this.mode = 'default';
        this.updateControls();
        return;
      }
      this.mode = state['mode'];
      this.selectedFinanceCategory = state['category'];
      this.updateControls();

  };

  @ViewChild('editForm') editForm!: ElementRef;

  mode: string;
  captionSuffix: string;
  selectedFinanceCategory: FinanceCategory;

  filter: TimeFrameFilter;
  timeframeCaption: string = "";

  income: boolean = true;
  expense: boolean = true;


  financeTransactions: FinanceTransaction[];
  showEditForm: boolean;

  financeCategories: FinanceCategory[] = [];
  recurringTypes: FinanceRecurringType[] = [];

  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);


    this.filter = {year : new Date().getFullYear()};
  }

  private updateControls() {
     if (this.mode == 'add') {
       this.captionSuffix = ' pro kategorii ' + this.selectedFinanceCategory.name;
     }
  }

  generate() {
    if (this.filter.month && this.filter.year) {
      this.financeTransactionService.generate(this.filter).subscribe( (r) => {
        alert("Vygenerovano " + r);
        this.loadData();
      });
    } else {
      alert("nepodporovano");
    }
  }



  changeCategory(e: any) {
    this.detailForm.get('categoryId').setValue(e.target.value, {
      onlySelf: true,
    });
  }

  detailForm = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>(null, Validators.required),
    transactionPrototypeId: new FormControl<number | null>(null, [Validators.min(0)]),
    categoryId: new FormControl<number | null>(null, Validators.required),
    amountPredictionMin: new FormControl<number | null>(null, [Validators.min(0)]),
    amountPrediction: new FormControl<number | null>(null, [Validators.min(0)]),
    amountPredictionMax: new FormControl<number | null>(null, [Validators.min(0)]),
    amountShouldGive: new FormControl<number | null>(null, [Validators.min(0)]),
    amountGave: new FormControl<number | null>(null, [Validators.min(0)]),
    note: new FormControl<string | null>(null),
  });

  loadData() {
    if (this.selectedFinanceCategory != null) {
      this.financeTransactionService.getByCategoryAndTimeFrame(this.selectedFinanceCategory.id, this.filter).subscribe((e) => {
        this.financeTransactions = e;
      });
    } else {
      this.financeTransactionService.getByTimeframe(this.filter).subscribe((e) => {
        this.financeTransactions = e;
      });
    }

    this.financeCategoryService.getAll().subscribe((e) => {
      this.financeCategories = e;
    });

    this.financeRecurringTypeService.getAll().subscribe((e) => {
      this.recurringTypes = e;
    });
  }

  newTransaction() {
    this.detailForm.reset();
    this.showEditForm = true;
    this.editForm.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onDelete(id: number) {
    this.financeTransactionService
      .delete(id)
      .subscribe((e) => this.loadData());
  }

  onEdit(id: number) {
    this.financeTransactionService.getById(id).subscribe((e) => {
      this.detailForm.setValue({
        id: e.id,
        name: e.name,
        amountGave: e.amountGave,
        amountShouldGive: e.amountShouldGive,
        amountPredictionMax: e.amountPredictionMax,
        amountPrediction: e.amountPrediction,
        amountPredictionMin: e.amountPredictionMin,
        note: e.note,
        categoryId: e.categoryId,
        transactionPrototypeId: e.transactionPrototypeId,
      });
      console.log("ahoj");
      this.showEditForm = true;
      console.log(this.editForm.nativeElement);
      this.editForm.nativeElement.scrollIntoView({ behavior: 'smooth' });
    });
  }

  onSave() {
    let detail = this.detailForm.getRawValue();
    let objTosave: FinanceTransaction = {
      id: detail.id,
      name: detail.name,
      amountGave: detail.amountGave,
      amountShouldGive: detail.amountShouldGive,
      amountPredictionMax: detail.amountPredictionMax,
      amountPrediction: detail.amountPrediction,
      amountPredictionMin: detail.amountPredictionMin,
      amountSave: 0,
      note: detail.note,
      categoryId: detail.categoryId,
      transactionPrototypeId: detail.transactionPrototypeId,
    };

    if (detail.id != null) {
      this.financeTransactionService.save(objTosave).subscribe((e) => {
        this.loadData();
        this.showEditForm = false;
      });
    } else {
      this.financeTransactionService
        .create(objTosave)
        .subscribe((e) => {
          this.loadData();
          this.showEditForm = false;
        });
    }
  }

  onTimeFrameSelected(evt: TimeframeAdjustEvent) {
    //async to avoid some change detection issues
    setTimeout(() => {
      this.filter.year = evt.selectedYear;
      this.filter.month = evt.selectedMonth;
      this.filter.quartal = evt.selectedQuarter;
      this.timeframeCaption = evt.prettyCaption;

      this.loadData();
    });
  }


  totalPay(transaction: FinanceTransaction) {
    this.financeTransactionService.totalPay(transaction.id).subscribe(detail => {
      this.loadData();
    });
  }


  goToTransactionDetail(transaction: FinanceTransaction)  {
    this.router.navigate(['finance/transaction-detail',transaction.id]);
  }

  toggleExpense() :void {
    this.expense = !this.expense;
  }

  toggleIncome() :void {
    this.income = !this.income;
  }
}
